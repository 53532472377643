<template>
    <v-data-table
        class="mt-4"
        v-if="charges.length > 0"
        :headers="headers"
        :items="charges"
        hide-default-footer
        disable-pagination
    >
        <template v-slot:item.info="{ item }">
            <div class="py-3">
                <template
                    v-for="({ icon, label, text, type }, index) in item.info"
                >
                    <div class="d-flex" :key="index">
                        <v-icon class="mr-3">{{ icon }}</v-icon>
                        <v-chip
                            label
                            small
                            class="mr-3"
                            color="grey lighten-3"
                            style="width: 90px"
                            >{{ label }}</v-chip
                        >
                        <v-spacer></v-spacer>
                        <v-chip
                            v-if="type === 'chip'"
                            :color="item.degreeColor"
                            dark
                            small
                            class="text-uppercase font-weight-bold"
                            >{{ text }}</v-chip
                        >
                        <span v-else class="font-weight-bold">{{ text }}</span>
                    </div>
                    <v-divider
                        v-if="index !== 2"
                        class="my-2"
                        color="grey ligthen-2"
                        :key="`divider-${index}`"
                    ></v-divider>
                </template>
            </div>
        </template>
        <template v-slot:item.dates="{ item }">
            <div class="py-2">
                <template
                    v-for="({ icon, date, from, label }, index) in [
                        item.dispositionDate,
                        item.offenseDate,
                        item.fileDate,
                    ]"
                >
                    <div class="d-flex" :key="index">
                        <v-icon class="mr-3">{{ icon }}</v-icon>
                        <span class="d-inline mr-3" style="width: 120px">{{
                            label
                        }}</span>
                        <h4>{{ date }}</h4>
                        <v-spacer></v-spacer>
                        <v-chip small>{{ from }}</v-chip>
                    </div>
                    <v-divider
                        v-if="index !== 2"
                        class="my-2"
                        color="grey ligthen-2"
                        :key="`divider-${index}`"
                    ></v-divider>
                </template>
            </div>
        </template>
        <template v-slot:item.description="{ item }">
            <div class="py-2">
                <span class="font-weight-bold text-subtitle-1">{{
                    item.detail.description
                }}</span>
                <div class="d-flex">
                    <span class="d-inline" style="width: 120px"
                        >Full Name:</span
                    >
                    <span class="font-weight-bold">
                        {{ item.caseDetails.fullName }}
                    </span>
                </div>
                <div class="d-flex">
                    <span
                        class="d-inline grey--text text--darken-1 text-body-2"
                        style="width: 120px"
                        >Address:</span
                    >
                    <span class="grey--text text--darken-2"
                        >{{ item.caseDetails.address1 }}
                    </span>
                </div>
                <div class="d-flex">
                    <span class="d-inline" style="width: 120px">DOB:</span>
                    <span class="font-weight-bold"
                        >{{ item.caseDetails.dob }}
                    </span>
                </div>
            </div>
        </template>
        <template v-slot:item.degree="{ item }">
            <v-chip
                label
                small
                class="text-center align-center d-flex justify-center"
                :color="item.degreeColor"
                style="width: 120px"
                ><h4 class="mx-auto text-center align-center">
                    {{ item.degree }}
                </h4></v-chip
            >
        </template>
        <template v-slot:item.disposition="{ item }">
            <v-chip
                small
                :color="item.dispositionColor"
                class="ml-4 pl-0 white--text"
            >
                <v-icon class="mr-1 white--text">{{
                    item.dispositionIcon
                }}</v-icon>
                {{ item.disposition }}</v-chip
            >
        </template>
    </v-data-table>
</template>
<script>
export default {
    name: "screen-charges-list",
    components: {},
    props: {
        charges: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        headers() {
            return [
                {
                    text: "Description",
                    align: "start",
                    sortable: false,
                    value: "description",
                },
                {
                    text: "Degree",
                    align: "start",
                    sortable: true,
                    value: "degree",
                },
                {
                    text: "Disposition",
                    align: "center",
                    sortable: true,
                    value: "disposition",
                },
                {
                    text: "Dates",
                    align: "start",
                    sortable: false,
                    value: "dates",
                },
                {
                    text: "Info",
                    align: "start",
                    sortable: false,
                    value: "info",
                },
            ];
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>