var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.charges.length > 0
    ? _c("v-data-table", {
        staticClass: "mt-4",
        attrs: {
          headers: _vm.headers,
          items: _vm.charges,
          "hide-default-footer": "",
          "disable-pagination": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.info",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    { staticClass: "py-3" },
                    [
                      _vm._l(item.info, function(ref, index) {
                        var icon = ref.icon
                        var label = ref.label
                        var text = ref.text
                        var type = ref.type
                        return [
                          _c(
                            "div",
                            { key: index, staticClass: "d-flex" },
                            [
                              _c("v-icon", { staticClass: "mr-3" }, [
                                _vm._v(_vm._s(icon))
                              ]),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "mr-3",
                                  staticStyle: { width: "90px" },
                                  attrs: {
                                    label: "",
                                    small: "",
                                    color: "grey lighten-3"
                                  }
                                },
                                [_vm._v(_vm._s(label))]
                              ),
                              _c("v-spacer"),
                              type === "chip"
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass:
                                        "text-uppercase font-weight-bold",
                                      attrs: {
                                        color: item.degreeColor,
                                        dark: "",
                                        small: ""
                                      }
                                    },
                                    [_vm._v(_vm._s(text))]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v(_vm._s(text))]
                                  )
                            ],
                            1
                          ),
                          index !== 2
                            ? _c("v-divider", {
                                key: "divider-" + index,
                                staticClass: "my-2",
                                attrs: { color: "grey ligthen-2" }
                              })
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ]
              }
            },
            {
              key: "item.dates",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    { staticClass: "py-2" },
                    [
                      _vm._l(
                        [item.dispositionDate, item.offenseDate, item.fileDate],
                        function(ref, index) {
                          var icon = ref.icon
                          var date = ref.date
                          var from = ref.from
                          var label = ref.label
                          return [
                            _c(
                              "div",
                              { key: index, staticClass: "d-flex" },
                              [
                                _c("v-icon", { staticClass: "mr-3" }, [
                                  _vm._v(_vm._s(icon))
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "d-inline mr-3",
                                    staticStyle: { width: "120px" }
                                  },
                                  [_vm._v(_vm._s(label))]
                                ),
                                _c("h4", [_vm._v(_vm._s(date))]),
                                _c("v-spacer"),
                                _c("v-chip", { attrs: { small: "" } }, [
                                  _vm._v(_vm._s(from))
                                ])
                              ],
                              1
                            ),
                            index !== 2
                              ? _c("v-divider", {
                                  key: "divider-" + index,
                                  staticClass: "my-2",
                                  attrs: { color: "grey ligthen-2" }
                                })
                              : _vm._e()
                          ]
                        }
                      )
                    ],
                    2
                  )
                ]
              }
            },
            {
              key: "item.description",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("div", { staticClass: "py-2" }, [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold text-subtitle-1" },
                      [_vm._v(_vm._s(item.detail.description))]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "span",
                        {
                          staticClass: "d-inline",
                          staticStyle: { width: "120px" }
                        },
                        [_vm._v("Full Name:")]
                      ),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(item.caseDetails.fullName) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "d-inline grey--text text--darken-1 text-body-2",
                          staticStyle: { width: "120px" }
                        },
                        [_vm._v("Address:")]
                      ),
                      _c("span", { staticClass: "grey--text text--darken-2" }, [
                        _vm._v(_vm._s(item.caseDetails.address1) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "span",
                        {
                          staticClass: "d-inline",
                          staticStyle: { width: "120px" }
                        },
                        [_vm._v("DOB:")]
                      ),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(item.caseDetails.dob) + " ")
                      ])
                    ])
                  ])
                ]
              }
            },
            {
              key: "item.degree",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-chip",
                    {
                      staticClass:
                        "text-center align-center d-flex justify-center",
                      staticStyle: { width: "120px" },
                      attrs: { label: "", small: "", color: item.degreeColor }
                    },
                    [
                      _c(
                        "h4",
                        { staticClass: "mx-auto text-center align-center" },
                        [_vm._v(" " + _vm._s(item.degree) + " ")]
                      )
                    ]
                  )
                ]
              }
            },
            {
              key: "item.disposition",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-chip",
                    {
                      staticClass: "ml-4 pl-0 white--text",
                      attrs: { small: "", color: item.dispositionColor }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1 white--text" }, [
                        _vm._v(_vm._s(item.dispositionIcon))
                      ]),
                      _vm._v(" " + _vm._s(item.disposition))
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          false,
          991306559
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }