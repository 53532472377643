<template>
    <div>
        <psi-toolbar
            title="Crime"
            :viewOption.sync="viewOption"
            :quicklinks="quicklinks"
            @quicklink-action="quicklinkAction"
        >
            <template v-for="({ color, count, label }, index) in counts">
                <v-chip
                    :color="color"
                    class="ml-2 text-uppercase pr-0"
                    :key="index"
                    small
                >
                    <span class="font-weight-bold">{{ label }}</span
                    >:
                    <v-chip
                        small
                        color="white"
                        class="ml-2 grey--text text--darken-3"
                        >{{ count }}</v-chip
                    >
                </v-chip>
            </template>
        </psi-toolbar>
        <v-slide-y-reverse-transition>
            <v-row v-if="charges.length && viewOption === 'grid'" class="mt-4">
                <template v-for="(charge, index) in charges">
                    <v-col lg="4" md="3" sm="6" :key="index">
                        <screen-charge
                            :charge="charge"
                            :key="index"
                        ></screen-charge>
                    </v-col>
                </template>
            </v-row>
        </v-slide-y-reverse-transition>
        <v-slide-y-reverse-transition>
            <screen-charges-list
                v-if="charges.length && viewOption === 'list'"
                :charges="charges"
            ></screen-charges-list>
        </v-slide-y-reverse-transition>

        <v-dialog v-model="dialog" fullscreen>
            <v-card height="100%">
                <div
                    style="margin:0px; padding:0px,height:100%,border:none; overflow:auto"
                >
                    <iframe
                        style="display:block; width:100%;  border:none, overflow-y:auto, overflow-x:hidden"
                        src="https://publicaccess.courts.oregon.gov/PublicAccessLogin/Login.aspx"
                        marginheight="0"
                        marginwidth="0"
                        width="100%"
                        height="960"
                        scrolling="no"
                    ></iframe>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ScreenChargesList from "./ScreenChargesList.vue";
import ScreenCharge from "./ScreenCharge.vue";
import WithCrimeRecords from "@components/screen/mixins/WithCrimeRecords";

export default {
    name: "screen-search",
    mixins: [WithCrimeRecords],
    components: {
        ScreenCharge,
        ScreenChargesList,
    },
    props: {},
    data() {
        return {
            viewOption: "grid",
            dialog: false,
        };
    },
    computed: {
        ...mapGetters("Screens", ["screenSearch"]),
        quicklinks() {
            return [
                {
                    icon: "mdi-gavel",
                    title: "Real-time Oregon eCourt ",
                    class: "warning--text text--darken-2",
                    slug: "realtime-ojin",
                },
            ];
        },
    },
    watch: {
        screenSearch: {
            immediate: true,
            handler(value) {
                this.records = value.ojin.tags;
            },
        },
    },
    created() {
        this.getScreenSearch();
    },
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        ...mapActions("Screens", ["getScreenSearch"]),
        quicklinkAction(item) {
            if (item.slug === "realtime-ojin") {
                this.dialog = true;
            }
        },
    },
};
</script>

<style scoped>
</style>